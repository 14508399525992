<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-bill display-6 text-primary"></i></div>
              <div>
                  <h4 class="fw-medium font-size-20 text-info mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">All IMPS Deposit Records from Merchant</small>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md col-xl">
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  @change="DateChanged()"
                  class="mb-2"
                ></date-picker>
              </div>
              <div class="col-12 col-md-auto col-xl">
                <div class="app-search2 position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    class="form-control mb-2"
                    v-model.trim="search_value"
                    @search="search()"
                  />
                  <span class="uil-search text-info"></span>
                </div>
              </div>
              <div class="col-12 col-md-auto col-xl-auto">
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14">
             
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0}">
                <thead class="text-uppercase">
                  <tr>
                    <th>Deposit Date</th>
                    <th class="needtoFreeze">Merchant</th>
                    <th>Deposit ID</th>
                    <th>Reference No.</th>
                    <th>Customer Name</th>
                    <th>From Bank</th>
                    <th>To Bank</th>
                    <th>Currency </th>
                    <th class="text-end">Deposit</th>
                    <th class="text-end">Merchant Fee</th>
                    <th class="text-end">Nett Amount</th>
                    <th class="text-center">Transaction ID</th>
                    <th class="text-end">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td colspan="12" height="300" class="text-center text-muted">
                      <i class="uil uil-bill me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="12" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  <tr v-for="(value, index) in returnData" :key="index" >
                    <td>
                      {{ value.deposit_date }}
                    </td>
                    <td class="needtoFreeze">
                      {{ value.merchant_code }}
                    </td>
                    <td>
                      {{ value.deposit_id }}
                    </td>
                    <td>
                      {{ value.merchant_ref_no }}
                    </td>
                    <td>
                    <div>{{ value.customer_name }} </div>
                    </td>
                    <td>
                      {{ value.account_bank_name }}<br>
                      {{ value.account_no }}
                    </td>
                    <td>
                      {{ value.admin_bank_name }}<br>
                      {{ value.admin_account_no }}
                      
                    </td>
                    <td class="text-center ">
                      {{ value.currency }}
                    </td>
                    <td class="text-end">
                      {{ currencySymbol }}  {{ value.deposit_amount }}
                    </td>

                    <td class="text-end text-primary fw-medium">
                      {{ currencySymbol }} {{ value.merchant_fee }}
                    </td>

                    <td class="text-end">
                      {{ currencySymbol }} {{ value.nett_amount }}
                    </td>
                    <td class="text-center">
                      <div v-if="value.status=='success'">{{ value.transaction_id || '-'}}</div>
                      <div v-else>-</div>
                    </td>
                    <td class="text-center">
                      <span class="text-success fw-medium my-2" v-if="value.status=='success'">Success</span>
                      <span class="text-danger fw-medium my-2" v-else-if="value.status=='failed'">Failed</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'animate/loading-168.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
/**
 * UPI Deposit Page
 */
const PageTitle = "IMPS Deposit"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "One68Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      currencySymbol:"₹",
      perPage: 20,
      totalData: 0,
      returnData: [],
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      shortcuts:[]
    };
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    console.log( this.$refs.commonFunc.getPhone())
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  methods:{
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
      },

      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.search_value = "";
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
        this.$Progress.start();
        this.loading = true
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("keyword", keyword);
        bodyFormData.append("dateStart", this.date_range[0]);
        bodyFormData.append("dateEnd", this.date_range[1]);
        axios({
            method: "post",
            url: `https://www.seveninblank.com/projects/api/pagination.php`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            this.totalData = resData.total_products
            var users = resData.products
           
            //const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            for (var i in users) {
                // // Generate a random deposit date within the last 30 days
                // const currentDate = new Date();
                // const randomDaysAgo = Math.floor(Math.random() * 30);
                // const depositDate = new Date(currentDate.getFullYear(), 5, 1); // Set the month to June
                // depositDate.setDate(depositDate.getDate() - randomDaysAgo);

                // // Generate random hours, minutes, and seconds
                // const randomHours = Math.floor(Math.random() * 24);
                // const randomMinutes = Math.floor(Math.random() * 60);
                // const randomSeconds = Math.floor(Math.random() * 60);

                // // Format the deposit date and time
                // const formattedDate = depositDate.getDate().toString().padStart(2, '0');
                // const formattedMonth = months[depositDate.getMonth()];
                // const formattedYear = depositDate.getFullYear();
                // const formattedTime = `${randomHours.toString().padStart(2, '0')}:${randomMinutes.toString().padStart(2, '0')}:${randomSeconds.toString().padStart(2, '0')}`;

                // Combine date and time
                const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const deposit_id = Math.floor(10000000 + Math.random() * 90000000);
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                const failedStatus = Math.random() < 0.8 ? "Order Expired" : "Password Error";
                const merchantCodes = ["M00001", "M00002", "M00003", "M00004", "M00005"];
                const randomIndex = Math.floor(Math.random() * merchantCodes.length);
                const merchantCode = merchantCodes[randomIndex];

                var namesArray = [
                    "Simon", "Liesl", "Asad", "Ellee", "Brienna", "Soren", "Blake", "Layken",
                    "Ainsley", "Aleiah", "Adel", "Marlon", "Eleni", "Seraphine", "Octavian",
                    "Katya", "Chanelle", "Taysom", "Taya", "Arielle"
                ];
                const randomNameIndex = Math.floor(Math.random() * namesArray.length);
                const randomName = namesArray[randomNameIndex];

                const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                const length = 12;
                let bankRef = "";
                for (let i = 0; i < length; i++) {
                  bankRef += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "deposit_id": deposit_id,
                  "merchant_code":merchantCode,
                  "merchant_ref_no": bankRef,
                  "admin_bank_name": "IndusInd Bank",
                  "admin_account_no": "10012096825",
                  "account_bank_name": "Axis Bank",
                  "account_no": "20022096823",
                  "currency": `INR`,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${randomName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode": "IMPS",
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "status": randomStatus,
                  "transaction_id":"IMPS/123443211240",
                  "failed_status": failedStatus,
                };
                this.returnData.splice(i, 0, listData);
              }
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 401){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     this.$refs.commonFunc.clearData()
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async exportData(){
        this.$Progress.start();
        this.disableExport = true;
        this.loadingButton = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        // bodyFormData.append("page", pages);
        // bodyFormData.append("limit", this.perPage);
        axios({
            method: "get",
            url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.export_data = [];
            var resData = response.data;
            this.totalData = resData.total
            var users = resData.users
           

            for (var i in users) {
                 // Combine date and time
                 const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode": "UPI",
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "status": randomStatus,
                };
                this.export_data.splice(i, 0, listData);
              }
              var self = this
              setTimeout(function() {  
                self.disableExport = false;
                self.loadingButton = false;
                self.exportToExcel(self.export_data,'Summary','xlsx')
              }, 1200);
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 401){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     this.$refs.commonFunc.clearData()
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disableExport = false;
            this.loadingButton = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.formData.apiID = data.apiID;
          this.formData.apiHash = data.apiHash;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>